.bono-title{
  align-items: center!important;

  h5{
    text-align: center;
    font-size: 1.275rem;
    font-weight: 600;
    color: #181C32;
    margin: 0px;
  }
}

.bar-component{
  h6{
    text-align: center;
    margin: 1% 5% 1% 5%;
  }

  p{
    text-align: center;
    margin: 0px 0px 0 0px;
  }

}

.bar{
  height:35px;
  width: 100%;
  margin:  0px 0px 0px 0px;
  color: black;
  background-color: white;

  border: 3px solid grey;
  border-radius: 50px;
  overflow: hidden;




}

.subBar{
  display: inline-block;
  // background-color: green;
  // width: 20%;
  height: 100%;
}
