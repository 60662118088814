.containerHelp {
    width: 210px;
    height: 500px;
    display: block;
    position: relative;
    margin: 0 auto 40px;
    border: 1px solid transparent;
    -webkit-perspective: 1100px;
    -moz-perspective: 1100px;
    perspective: 1100px;

}

#carouselCus {
    width: 100%;
    height: 100%;
    position: absolute;
    -webkit-transform: translateZ( -288px );
    -moz-transform: translateZ( -288px );
    transform: translateZ( -288px );
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-animation: rotateInY 40s infinite linear;
    -moz-animation: rotateInY 40s infinite linear;
    animation: rotateInY 40s infinite linear;
}


#carouselCus figure {
    display: block;
    position: absolute;
    width: 100%;
    height: 95%;
    left: 10px;
    top: 10px;
    font-weight: bold;
    color: white;
    text-align: center;
    margin: 0;
}

#carouselCus figure:nth-child(1).Six{
    -webkit-transform: rotateY(0deg) translateZ(288px);
    -moz-transform: rotateY(0deg) translateZ(288px);
    transform: rotateY(0deg) translateZ(288px);
}
#carouselCus figure:nth-child(2).Six{
    -webkit-transform: rotateY(60deg) translateZ(288px);
    -moz-transform: rotateY(60deg) translateZ(288px);
    transform: rotateY(60deg) translateZ(288px);
}
#carouselCus figure:nth-child(3).Six{
    -webkit-transform: rotateY(120deg) translateZ(288px);
    -moz-transform: rotateY(120deg) translateZ(288px);
    transform: rotateY(120deg) translateZ(288px);
}
#carouselCus figure:nth-child(4).Six{
    -webkit-transform: rotateY(180deg) translateZ(288px);
    -moz-transform: rotateY(180deg ) translateZ(288px);
    transform: rotateY(180deg) translateZ(288px);
}
#carouselCus figure:nth-child(5).Six{
    -webkit-transform: rotateY(240deg) translateZ(288px);
    -moz-transform: rotateY(240deg ) translateZ(288px);
    transform: rotateY(240deg) translateZ(288px);
}
#carouselCus figure:nth-child(6).Six{
    -webkit-transform: rotateY(300deg) translateZ(288px);
    -moz-transform: rotateY(300deg ) translateZ(288px);
    transform: rotateY(300deg) translateZ(288px);
}


/* 4 with different id Names in 90 degrees intervals */
#carouselCus figure:nth-child(1).Four {
    -webkit-transform: rotateY(0deg) translateZ(288px);
    -moz-transform: rotateY(0deg) translateZ(288px);
    transform: rotateY(0deg) translateZ(288px);
}
#carouselCus figure:nth-child(2).Four {
    -webkit-transform: rotateY(90deg) translateZ(288px);
    -moz-transform: rotateY(90deg) translateZ(288px);
    transform: rotateY(90deg) translateZ(288px);
}
#carouselCus figure:nth-child(3).Four {
    -webkit-transform: rotateY(180deg) translateZ(288px);
    -moz-transform: rotateY(180deg) translateZ(288px);
    transform: rotateY(180deg) translateZ(288px);
}
#carouselCus figure:nth-child(4).Four {
    -webkit-transform: rotateY(270deg) translateZ(288px);
    -moz-transform: rotateY(270deg ) translateZ(288px);
    transform: rotateY(270deg) translateZ(288px);
}

.innerText{
    position: absolute;
    top: 200px;
    padding: 20px;
    color: #5f5f5f;
}

.titleC{
    font-size: 50px;
}
.describe{
    font-size: 12px;
}

@media only screen and (max-width: 415px){

    .containerHelp {
        width: 150px;
        height: 500px;
        display: block;
        position: relative;
        margin: 0 auto 40px;
        border: 1px solid transparent;
        -webkit-perspective: 1100px;
        -moz-perspective: 1100px;
        perspective: 1100px;
    }


    #carouselCus figure:nth-child(1).Six {
        -webkit-transform: rotateY(0deg) translateZ(288px);
        -moz-transform: rotateY(0deg) translateZ(288px);
        transform: rotateY(0deg) translateZ(200px);
    }
    #carouselCus figure:nth-child(2).Six {
        -webkit-transform: rotateY(60deg) translateZ(288px);
        -moz-transform: rotateY(60deg) translateZ(288px);
        transform: rotateY(60deg) translateZ(200px);
    }
    #carouselCus figure:nth-child(3).Six {
        -webkit-transform: rotateY(120deg) translateZ(288px);
        -moz-transform: rotateY(120deg) translateZ(288px);
        transform: rotateY(120deg) translateZ(200px);
    }
    #carouselCus figure:nth-child(4).Six {
        -webkit-transform: rotateY(180deg) translateZ(288px);
        -moz-transform: rotateY(180deg ) translateZ(288px);
        transform: rotateY(180deg) translateZ(200px);
    }
    #carouselCus figure:nth-child(5).Six {
        -webkit-transform: rotateY(240deg) translateZ(288px);
        -moz-transform: rotateY(240deg ) translateZ(288px);
        transform: rotateY(240deg) translateZ(200px);
    }
    #carouselCus figure:nth-child(6).Six {
        -webkit-transform: rotateY(300deg) translateZ(288px);
        -moz-transform: rotateY(300deg ) translateZ(288px);
        transform: rotateY(300deg) translateZ(200px);
    }


    /* 4 with different id Names in 90 degrees intervals */
    #carouselCus figure:nth-child(1).Four {
        -webkit-transform: rotateY(0deg) translateZ(288px);
        -moz-transform: rotateY(0deg) translateZ(288px);
        transform: rotateY(0deg) translateZ(200px);
    }
    #carouselCus figure:nth-child(2).Four {
        -webkit-transform: rotateY(90deg) translateZ(288px);
        -moz-transform: rotateY(90deg) translateZ(288px);
        transform: rotateY(90deg) translateZ(200px);
    }
    #carouselCus figure:nth-child(3).Four {
        -webkit-transform: rotateY(180deg) translateZ(288px);
        -moz-transform: rotateY(180deg) translateZ(288px);
        transform: rotateY(180deg) translateZ(200px);
    }
    #carouselCus figure:nth-child(4).Four {
        -webkit-transform: rotateY(270deg) translateZ(288px);
        -moz-transform: rotateY(270deg ) translateZ(288px);
        transform: rotateY(270deg) translateZ(200px);
    }
} /* Media only screen */



@keyframes rotateInY {
    0%   { -webkit-transform: translateZ(-288px) rotateY(0deg);   }
    100% { -webkit-transform: translateZ(-288px) rotateY(360deg);    }
}

@-webkit-keyframes rotateInY {
    0%   { -webkit-transform: -webkit-translateZ(-288px) rotateY(0deg);   }
    100% { -webkit-transform: -webkit-translateZ(-288px) rotateY(360deg);    }
}

@-moz-keyframes rotateInY {
    0%   { -moz-transform: -moz-translateZ(-288px) rotateY(0deg);   }
    100% { -moz-transform: -moz-translateZ(-288px) rotateY(360deg);    }
}
