
.info-title{
  align-items: center!important;

  h5{
    font-size: 20px;
    margin: 0px;
  }
}

.photo-card{

  display: flex;
  flex-direction: column;
  align-items: center;

  img{
    border: 5px solid #00A19C;
    border-radius: 50%;
    width: 69%;
    height: 69%;
    align-items: center;
    margin: 0px 0px 25px 0px;
  }

  h6{
    font-size: 20px;
    color: #181C32;
  }
  p {
    font-size: 20px;
    color: #B3B3B3;
  }
}


.info-card{

  display: flex!important;
  flex-direction: column!important;
  align-items: left!important;

  .input-apellidos{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    p{
      width: 45%;
      margin: 0px 0px 0px 0px;
    }
  }


  label{
    color: #181C32;
    font-weight: 500;
    font-size: 16px;
    margin: 0px 0px 0px 0px;
  }

  input{
    font-weight: 500;
    font-size: 14px;
    border-radius: 5px;
    border-style: solid;
    border-color: #e6e6e6;
    height: 30px;
    width: 100%;
    margin: 0px 0px 20px 0px;

    &#apellidos{
      height: 30px;
      width: 98%;
      margin: 0px 0px 20px 0px;
    }
  }

  button{
    height: 30px;
    width: 75%;
    margin: 15px 12.5%;

    background-color: #00A19C;
    border: 0px none #00A19C;
    border-radius: 5px;

    color: white;
    font-size: 1.15rem;
    font-weight: 600;

    &:hover{
      background-color: #005850;
    }
    &:active{
      background-color: #00A19C;
    }
  } //button

} //info-card
